// ContactSection.js

import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactImge from "../Images/Contact_Form.webp";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const ContactSection = React.forwardRef((props, ref) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required."),
    lastName: Yup.string().required("Last Name is required."),
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
    message: Yup.string().required("Message is required."),
  });

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await axios.post(
        "https://techsol-mail-api-58xf.vercel.app/api/contact",
        values
      );
      resetForm();
      toast.success("Message sent successfully");
      // Optionally, display a success message to the user
    } catch (error) {
      toast.error("Message not delivered");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section ref={ref} className="bg-[#141416] text-white py-20 px-6 lg:px-24">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        {/* Image Section */}
        <div className="w-full md:w-1/2 mb-12 md:mb-0">
          <img
            src={ContactImge}
            alt="Professional Meeting"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
          />
        </div>

        {/* Contact Form Section */}
        <div className="w-full md:w-1/2 md:pl-16">
          <h2 className="text-5xl font-extrabold mb-6 leading-tight">
            Get in Touch
          </h2>
          <p className="text-lg font-light mb-6 leading-relaxed">
            Have a question, project idea, or need for expert consultation?
            We're here to help. Reach out to Techsol Solutions, your trusted
            partner for IT solutions across Pakistan.
          </p>

          {/* Contact Information */}
          <div className="mb-8">
            <p className="font-semibold text-xl">Techsol Solutions HQ</p>
            <p className="text-gray-400">
              <a
                href="mailto:info@techsolsol.com"
                className="hover:text-white transition duration-200"
              >
                info@techsolsol.com
              </a>{" "}
              | +92 21 343701647
            </p>
          </div>

          {/* Form */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="w-full p-4 text-sm text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8A181A] shadow-lg"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="w-1/2">
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className="w-full p-4 text-sm text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8A181A] shadow-lg"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
                <div>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email *"
                    className="w-full p-4 text-sm text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8A181A] shadow-lg"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div>
                  <Field
                    as="textarea"
                    name="message"
                    placeholder="Message"
                    className="w-full p-4 text-sm text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8A181A] shadow-lg h-32"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-8 py-4 bg-gradient-to-r from-[#8A181A] to-red-600 text-white rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-[#8A181A] focus:ring-opacity-50 transition duration-300 shadow-lg"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </Form>
            )}
          </Formik>

          {/* Social Media Links */}
          <div className="mt-8 flex space-x-6 text-lg">
            <a
              href="https://www.linkedin.com/company/techsol-solutions/?viewAsMember=true"
              className="hover:text-gray-300 transition duration-200"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.facebook.com/techsols0lutions"
              className="hover:text-gray-300 transition duration-200"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/techsol08/?igsh=OWprdzlpazlsd3A5"
              className="hover:text-gray-300 transition duration-200"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});

export default ContactSection;
